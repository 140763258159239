<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3">{{ $tc('user.title', 2) }}</h1>
    <Resource
        :create-request="createHandler"
        :delete-request="deleteHandler"
        :form-component="() => import('@/components/forms/UserForm.vue')"
        :index-request="indexHandler"
        :meta="{name: $tc('user.title', 1), namePlural: $tc('user.title', 2)}"
        :model-type="modelType"
        :show-request="showHandler"
        :table-content="tableContent"
        :update-request="updateHandler"
        show-speed-dial
    />
  </v-container>
</template>

<script lang="js">
import Resource from '@/components/vuetifyResource/Resource.vue';
import eventBus from '@/eventBus';
import { create, index, remove, show, update } from '@/api/endpoints/admin/user.js';
import User from '@/application/models/admin/user.js';

export default {
  name: 'UserResource',
  components: {
    Resource,
  },
  computed: {
    indexHandler: () => index,
    showHandler: () => show,
    updateHandler: () => update,
    deleteHandler: () => remove,
    createHandler: () => create,
    modelType: () => User,
    tableContent() {
      return [
        {
          text: this.$t('user.fields.name'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('user.fields.email'),
          align: 'left',
          sortable: true,
          value: 'email',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'users' },
        text: this.$tc('user.title', 2),
      },
    ]);
  }
};
</script>
