import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { destroy, get, post, put } from '@/api/implementation/app';

export const index = (...args) => getPaginated(`admin/user`, ...args);

export const userAutocomplete = (...args) => getPaginated(`user/autocomplete`, ...args);

export const show = (objectId) => get(`admin/user/${objectId}`);

export const create = (object) => post(`admin/user`, object);

export const update = (object) => put(`admin/user/${object.id}`, object);

export const remove = (objectId) => destroy(`admin/user/${objectId}`);
