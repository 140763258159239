import Model from '@/application/models/model.js';
import dashboardTypes from '@/application/types/dashboard.json';

export default class User extends Model {
  id = 0;
  isAdmin = false;
  name = '';
  email = '';
  roles = [];
  startUrl = null;
  favoriteUrl = null;
  dashboard = null;
  dashboardType = dashboardTypes.DEFAULT;

  mapForRequest() {
    if (this.dashboardType !== dashboardTypes.START_PAGE) this.dashboard = null;
    if (this.dashboardType !== dashboardTypes.DIRECT_URL) this.startUrl = null;

    return {
      name: this.name,
      isAdmin: this.isAdmin,
      email: this.email,
      roles: this.roles.map(({ id }) => id),
      startUrl: this.startUrl,
      favoriteUrl: this.favoriteUrl,
      dashboardId: this.dashboard?.id || null,
    };
  }

  mapResponse(data) {
    const startUrl = data.startUrl;
    const dashboard = data.dashboard;
    return super.mapResponse({
      ...data,
      dashboardType: startUrl
        ? dashboardTypes.DIRECT_URL
        : dashboard
          ? dashboardTypes.START_PAGE
          : dashboardTypes.DEFAULT,
    });
  }
}
