import VuetifyResource from '@kingscode/vuetify-resource';

VuetifyResource.use({
  style: {
    default: {
      color: '#222222',
      size: 18,
      onlyCrudActions: true,
    },
  },
});

export default VuetifyResource;
