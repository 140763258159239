import Qs from "qs";
import { snakeToCamel } from "@/api/util/keyConverter.js";

export const getOnlySetQueryParameters = (params) =>
    Object.fromEntries(
        Object.entries(params || {}).filter(
            ([, value]) =>
                value !== null &&
                value !== "" &&
                value !== undefined &&
                !(Array.isArray(value) && value.length === 0)
        )
    );

function convertQueryParam(value) {
    if (!value) return undefined;
    if (typeof value === "string" && !isNaN(value)) return Number(value);
    if (Array.isArray(value)) return value.map(convertQueryParam);
    return value;
}

export const getConvertedQueryParams = (query) => {
    const params = snakeToCamel(Qs.parse(query, { allowSparse: false }));
    return Object.fromEntries(Object.entries(params)?.map(([key, value]) => ([key, convertQueryParam(value)]))) || {};
}
