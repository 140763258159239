<template>
  <vuetify-resource
    ref="resourceList"
    v-model="selected"
    v-bind="$attrs"
    :page="pagination.page"
    :beforeCreateCallback="beforeOpenCreateHandler"
    :beforeUpdateCallback="beforeOpenUpdateHandler"
    :createCallback="handleCreateRequest"
    :deleteCallback="handleDeleteRequest"
    :getDataCallback="handleIndexRequest"
    :getItemCallback="handleShowRequest"
    :meta="meta"
    :default-sort-by="currentResourceParams?.pagination?.sortBy"
    :default-sort-desc="currentResourceParams?.pagination?.sortDesc"
    :tableContent="tableContent"
    :texts="require('@/components/vuetifyResource/VuetifyResourceTexts.json')"
    :updateCallback="handleUpdateRequest"
    @row-click="onRowClick"
    :rowsPerPage="pagination.itemsPerPage"
  >
    <div slot="createContent">
      <component :is="formComponent"
                 ref="createForm"
                 v-model="createForm"
                 :errors="errors"
                 :is-update-form="false"/>
    </div>
    <div slot="updateContent">
      <component :is="formComponent"
                 ref="updateForm"
                 v-model="updateForm"
                 :errors="errors"
                 :is-update-form="true"/>
    </div>
    <template v-slot:crudActionsAfter="slotProps">
      <slot v-bind="slotProps" name="crudActionsAfter"></slot>
    </template>
    <template v-slot:speedDialAfter="slotProps">
      <slot v-bind="slotProps" name="speedDialAfter"></slot>
    </template>
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name"/>
    </template>
  </vuetify-resource>
</template>

<script>
import VuetifyResource from "@/plugins/vuetifyResource";
import { mapGetters, mapMutations } from "vuex";
import { getConvertedQueryParams } from "@/application/util/queryString";

export default {
  name: 'Resource',
  components: { VuetifyResource },
  data() {
    return {
      createForm: { values: {} },
      updateForm: { values: {} },
      errors: {},
      selected: [],
      pagination: {},
    };
  },
  props: {
    modelType: {
      type: Function,
      required: false,
    },
    tableContent: {
      type: Array,
      required: true,
    },
    formComponent: {
      required: false,
    },
    meta: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: 'item',
          namePlural: 'items',
          search: 'test1',
          searchQuery: 'test2',
        };
      },
    },
    indexRequest: {
      type: Function,
      required: true,
    },
    showRequest: {
      type: Function,
    },
    createRequest: {
      type: Function,
    },
    updateRequest: {
      type: Function,
    },
    deleteRequest: {
      type: Function,
    },
    beforeOpenUpdate: {
      type: Function,
      required: false,
    },
    beforeOpenCreate: {
      type: Function,
      required: false,
    },
    params: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.pagination = this.currentResourceParams?.pagination || {
      page: 1,
      itemsPerPage: 10,
    };
    this.trySetParams();
  },
  computed: {
    ...mapGetters("deviceSettings", ["getSelectedRouteResourceParams"]),
    currentResourceParams() {
      return this.getSelectedRouteResourceParams(this.$route.name);
    },
  },
  watch: {
    params: {
      handler() {
        if (!this.currentResourceParams.pagination?.page) {
          this.currentResourceParams.pagination.page = 1;
          this.$refs.resourceList.pagination.page = this.currentResourceParams.pagination.page;
        }
        this.$refs.resourceList.getDataHandler();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("deviceSettings", ["setRouteResourceParams"]),
    onRowClick(item) {
      this.$emit('row-click', item);
    },
    trySetParams() {
      const cached = this.currentResourceParams?.filters && JSON.parse(JSON.stringify(this.currentResourceParams?.filters));
      const query = this.$route.query ? getConvertedQueryParams(this.$route.query) : undefined;
      const hasParams = !!this.params && Object.keys(this.params).length > 0;
      const values = query && Object.keys(query).length > 0 ? query : cached;
      if (hasParams || !values || Object.keys(values).length === 0) return;
      this.$emit('update:params', values);
    },
    /***
     * @param pagination
     * @param search
     */
    handleIndexRequest(pagination, search) {
      const { sortBy, sortDesc } = pagination;

      const params = JSON.parse(JSON.stringify(this.params));

      this.setRouteResourceParams({
        routeName: this.$route.name,
        params: {
          pagination: {
            ...this.pagination,
            sortBy: sortBy?.[0],
            sortDesc: sortDesc?.[0],
          },
          filters: params,
          search: this.search,
        },
      });

      return new Promise((resolve, reject) => {
        const sorting = {};
        if (sortBy[0]) {
          sorting.sortBy = sortBy[0];
          sorting.desc = sortDesc[0] ? 1 : 0;
        }

        this.indexRequest(this.pagination.page, this.pagination.itemsPerPage, search, sorting.sortBy, sorting.desc, params)
          .then((response) => {
            const items = response.data.data;
            const total = response.data.meta.total;
            resolve({
              items,
              total,
            });
          })
          .catch(() => reject());

      });
    },
    handleShowRequest(id) {
      return new Promise((resolve) => {
        this.showRequest(id)
          .then((response) => {
            resolve({
              item: response.data.data,
            });
          });

      });
    },
    handleCreateRequest() {
      this.errors = {};
      this.$refs.createForm.validate();

      return new Promise((resolve, reject) => {
        process.nextTick(() => {
          if (this.createForm.valid) {
            this.createRequest(this.createForm.values)
              .then(() => {
                this.createForm.values = {};
                resolve();
              })
              .catch((error) => {
                this.errors = error.response.data.errors;
                this.$refs.createForm.validate();
                reject();
              });
          } else {
            reject();
          }
        });

      });
    },
    handleUpdateRequest() {
      this.errors = {};
      this.$refs.updateForm.validate();

      return new Promise((resolve, reject) => {
        process.nextTick(() => {
          if (this.updateForm.valid) {
            this.updateRequest(this.updateForm.values)
              .then(() => resolve())
              .catch((error) => {
                this.errors = error.response.data.errors;
                reject();
              });
          } else {
            this.$refs.updateForm.validate();
            reject();
          }
        });
      });

    },
    handleDeleteRequest(ids) {
      return new Promise((resolve, reject) => {
        const promises = [];
        ids.forEach((id) => {
          promises.push(this.deleteRequest(id));
        });

        Promise.all(promises)
          .then(() => {
            resolve();
          })
          .catch(() => reject());

      });
    },
    /**
     * The methods below are functions for wrapping function which are set by a prop.
     * This is because a props default cant access the this, so it can't call other functions
     */
    beforeOpenCreateHandler(selected) {
      if (this.beforeOpenCreate) {
        this.beforeOpenCreate(selected);
      }
      if (this.modelType) {
        this.createForm.values = new this.modelType();
      }
    },
    beforeOpenUpdateHandler(selected) {
      if (this.beforeOpenUpdate) {
        this.beforeOpenUpdate(selected);
        return;
      }
      if (!this.modelType) {
        this.updateForm.values = selected[0];
        return;
      }

      this.updateForm.values = new this.modelType().mapResponse(selected[0]);
    },
  },
};
</script>
